<template>
    <div class="stepsdemo-content">
        <Card>
           <template v-slot:title>
                {{$t('Información Adicional')}}
            </template>
            <template v-slot:subtitle>
                {{$t('solicitada')}}
            </template>
            <template v-slot:content>
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-5">
                        <Checkbox v-model="adicional.pregunta1" :binary="true" />
						{{$t('¿Tiene alguna discapacidad / enfermedad física o de otro tipo?')}}
					</div>
                    <div class="p-field p-col-1">
                        <strong v-if="adicional.pregunta1">{{$t('¿cuál?')}}</strong>
                    </div>
                    <div class="p-field p-col-6">
						<InputText v-model="adicional.respuesta1"  type="text"  v-if="adicional.pregunta1"/>
                        <small v-show="validationErrors.respuesta1 && submitted && adicional.pregunta1" class="p-error">{{$t('required')}}</small>
                    </div>
					<div class="p-field p-col-3">
                        <Checkbox v-model="adicional.pregunta2" :binary="true" />
						{{$t('¿Has sido condenada penalmente?')}}
					</div>
                    <div class="p-field p-col-2">
                        <strong v-if="adicional.pregunta2">{{$t('indique los detalles')}}</strong>   
                    </div>
                    <div class="p-field p-col-7" >
						<InputText v-model="adicional.respuesta2"  type="text" v-if="adicional.pregunta2"/>
                        <small v-show="validationErrors.respuesta2 && submitted  && adicional.pregunta2" class="p-error">{{$t('required')}}</small>
                    </div>
					<div class="p-field p-col-4">
                        <Checkbox v-model="adicional.pregunta3" :binary="true" />
						{{$t('¿Alguna vez le han negado el seguro?')}}
					</div>
					<div class="p-field p-col-3">
                        <strong v-if="adicional.pregunta3">{{$t('¿por qué empresa y cuál fue el motivo?')}}</strong>
					</div>
                    <div class="p-field p-col-5">
						<InputText v-model="adicional.respuesta3"  type="text" v-if="adicional.pregunta3"/>
                        <small v-show="validationErrors.respuesta3 && submitted  && adicional.pregunta3" class="p-error">{{$t('required')}}</small>
                    </div>
					<div class="p-field p-col-4">
                        <Checkbox v-model="adicional.pregunta4" :binary="true" />
						{{$t('¿Existen circunstancias circundantes que sean importantes para aceptar este seguro?')}}
					</div>
					<div class="p-field p-col-2">
                        <strong v-if="adicional.pregunta4">{{$t('indique los detalles')}}</strong>
					</div>
                    <div class="p-field p-col-6">
						<InputText v-model="adicional.respuesta4"  type="text"  v-if="adicional.pregunta4"/>
                        <small v-show="validationErrors.respuesta4 && submitted && adicional.pregunta4" class="p-error">{{$t('required')}}</small>
                    </div>

					<div class="p-field p-col-4">
                        <Checkbox v-model="adicional.pregunta5" :binary="true" />
						{{$t('¿O se imponen condiciones agravadas?')}}
					</div>
                    <div class="p-field p-col-1">
                        <strong v-if="adicional.pregunta5">{{$t('¿cuál?')}}</strong>
                    </div>
                    <div class="p-field p-col-7">
						<InputText v-model="adicional.respuesta5"  type="text" v-if="adicional.pregunta5"/>
                        <small v-show="validationErrors.respuesta5 && submitted  && adicional.pregunta5" class="p-error">{{$t('required')}}</small>
                    </div>
					<div class="p-field p-col-3">
						<label for="state">{{$t('¿Descuento por no siniestralidad %?')}}</label>
                        <small v-show="validationErrors.noclain && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-2">
                        <strong v-if="i18n.locale() == 'es'">SI </strong>
                        <strong v-else>YES </strong>
                        <RadioButton id="category3" name="subcateg" :value="true" v-model="adicional.noclain"/>
					</div>
					<div class="p-field p-col-2">
                        <strong v-if="i18n.locale() == 'es'">NO </strong>
                        <strong v-else>NO </strong>
                        <RadioButton id="category3" name="subcateg" :value="false" v-model="adicional.noclain"/>
					</div>
					<div class="p-field p-col-2" v-if="adicional.noclain">
						<label>{{$t('Documento')}}</label>
						<FileUpload :chooseLabel="$t('Subir Foto')+' JPG,JPEG'" uploadLabel="Cargar" mode="basic" :name="img" :url="url.api+'upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="myUploader(img)" :auto="true"/>
                        <small class="p-error">{{$t('tamaño máximo')+": 4MB"}}</small><br>
                        <small class="p-error"  v-if="adicional.noclain">{{$t('noclaimens')}}</small>

                        <small v-show="validationErrors.foto && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-3">
						<img style="width: 100px;" :src="url.upload+adicional.document" v-if="foto"/>
					</div>
					<div class="p-field p-col-2">
					</div>
					<div class="p-field p-col-3"  v-if="adicional.noclain">
						<label for="state">{{$t('¿Ha sido cliente IIC anteriormente?')}}</label>
                        <small v-show="validationErrors.noclain2 && submitted" class="p-error">{{$t('required')}}</small>
					</div>
					<div class="p-field p-col-2" v-if="adicional.noclain">
                        <strong v-if="i18n.locale() == 'es'">SI </strong>
                        <strong v-else>YES </strong>
                        <RadioButton id="category3" name="subcateg" :value="true" v-model="adicional.noclainiic"/>
					</div>
					<div class="p-field p-col-2" v-if="adicional.noclain">
                        <strong v-if="i18n.locale() == 'es'">NO </strong>
                        <strong v-else>NO </strong>
                        <RadioButton id="category3" name="subcateg" :value="false" v-model="adicional.noclainiic"/>
					</div>
					<div class="p-field p-col-2" v-if="adicional.noclainiic">
						<label>{{$t('Si recuerda el año indiquelo aquí')}}</label>
						<InputMask v-model="adicional.noclainyear" mask="9999"/>
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button :label="$t('Anterior')" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button :label="$t('Siguiente')" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import API from "@/service/ApiConsulta";
import { useI18nPlugin } from '@unify/vuex-i18n';
import Token from "uuid-token-generator";
import url from "@/service/_URL";

export default {
    data () {
        return {
            submitted: false,
            validationErrors: {},
			marcas: [],
			modelos: [],
			tipos: null,
			adicional: {
				pregunta1: false,
				respuesta1: null,
				pregunta2: false,
				respuesta2: null,
				pregunta3: false,
				respuesta3: null,
				pregunta4: false,
				respuesta4: null,
				pregunta5: false,
				respuesta5: null,
				noclain: false,
				document: null,
                noclainiic: false,
                noclainyear: 0
			},
            size: '40vw',
            img: null,
			foto: false,
        }
    },
	created() {
        if (this.$store.state.mobile){this.size = '80vw';}
		this.i18n = useI18nPlugin();
        this.url = url;
		const tokgen = new Token(256);
		this.img = tokgen.generate();
	},
    methods: {
        nextPage() {
            this.submitted = true;
            if (this.validateForm() ) {
                this.$emit('next-page', {formData: {adicional: this.adicional}, pageIndex: 3});
            }
        },
        prevPage() {
            this.$emit('prev-page', {pageIndex: 3});
        },
		myUploader(img) {
			this.$store.commit('Loading');
			const Consulta = new API('Cotizacions');
			Consulta.Procesar('Vehiculo',{
				caso: 	'RevFoto',
				foto: img,
			}).then(response => {
				if(response.result){
					this.adicional.document = response.result;
					this.foto = response.result;
				} else {
					this.foto = response.result;
					this.$toast.add({
						severity:'warn', 
						summary: this.$t('Advertencia'), 
						detail: this.$t('Error en Foto'), 
						life: 3000
					});
				}
                const tokgen = new Token(256);
                this.img = tokgen.generate();
				this.$store.commit('Loading');
			});
		},
        validateForm() {
            if (this.adicional.pregunta1 && (this.adicional.respuesta1==null || this.adicional.respuesta1==''))
               this.validationErrors['respuesta1'] = true;
            else
               delete this.validationErrors['respuesta1'];
            if (this.adicional.pregunta2 && (this.adicional.respuesta2==null || this.adicional.respuesta2==''))
               this.validationErrors['respuesta2'] = true;
            else
               delete this.validationErrors['respuesta2'];
            if (this.adicional.pregunta3 && (this.adicional.respuesta3==null || this.adicional.respuesta3==''))
               this.validationErrors['respuesta3'] = true;
            else
               delete this.validationErrors['respuesta3'];
            if (this.adicional.pregunta4 && (this.adicional.respuesta4==null || this.adicional.respuesta4==''))
               this.validationErrors['respuesta4'] = true;
            else
               delete this.validationErrors['respuesta4'];
            if (this.adicional.pregunta5 && (this.adicional.respuesta5==null || this.adicional.respuesta5==''))
               this.validationErrors['respuesta5'] = true;
            else
               delete this.validationErrors['respuesta5'];
            if (this.adicional.noclain==null)
               this.validationErrors['noclain'] = true;
            else
               delete this.validationErrors['noclain'];
            if (!this.foto && this.adicional.noclain)
               this.validationErrors['foto'] = true;
            else
               delete this.validationErrors['foto'];

            return !Object.keys(this.validationErrors).length;
        }
    }
}
</script>